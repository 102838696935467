import React, { useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FooterItems } from "./FooterItems";
import facebookLogo from '../../assets/images/logos/facebook-logo.png';
import instagramLogo from '../../assets/images/logos/instagram-logo.png';
import neritoLogoCombined from '../../assets/images/logos/nerito-logo-combined.png';
import linkedinLogo from '../../assets/images/logos/linkedin-logo.png';
import twitterLogo from '../../assets/images/logos/twitter-logo.png';
import youtubeLogo from '../../assets/images/logos/youtube-logo.png';
import tiktokLogo from '../../assets/images/logos/tiktok-logo.png';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import NeritoLogoGreen from '../../assets/images/logos/neritoLogoGreen.svg';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  footerContainer: {
    width: '100%',
    backgroundColor: theme.palette.white.main,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    zIndex: '-1',
    display: 'flex',
    flexDirection: 'row',
    height: '200px',
    alignItems: 'center',
    marginBottom: 0,
    boxShadow: theme.shadows[5]
  },
  footerFilter: {
    // height: '100%',
    width: '60%',
    zIindex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '75%'
  },
  logoContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    borderRightWidth: '3px',
    borderRightColor: theme.palette.secondary.main,
    borderRightStyle: 'solid',
    height: '75%'
  },
  footerTop: {
    position: 'relative',
    zIndex: '2',
    textAlign: 'center',
    // marginBottom: '20px',
  },
  copyright: {
    fontSize: '14px',
    paddingTop: '10px',
    color: theme.palette.black.main,
    fontFamily: 'Lato',
    fontWeight: '500'
  },
  singleRowLogo: {
    display: '-ms-flexbox',
    display: 'flex',
    MsFlexWrap: 'wrap',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  socialLogo: {
    width: '50px',
    margin: '5px 10px',
    borderRadius: '25px',
  },
  footerBottom: {
    zIndex: '2',
    position: 'relative',
    paddingBottom: '40px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  navLi: {
    display: 'inline-block',
    padding: '0px 0 0 8px',
    transition: 'all 0.3s ease 0s',
  },
  logoContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    borderRightWidth: '3px',
    borderRightColor: theme.palette.secondary.main,
    borderRightStyle: 'solid',
    height: '75%'
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    alignSelf: 'center',
    marginBottom: '10px'
  },
  link: {
    fontSize: '14px',
    color: theme.palette.primary.main,
    fontFamily: 'Lato',
    fontWeight: '600',
    textDecorationColor: theme.palette.primary.main,
  }

})

function Footer() {
  // const [click, setClick] = useState(false);
  const [click] = useState(false);

  // const handleClick = () => setClick(!click);

  // const closeMobileMenu = () => setClick(false);

  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <div className={classes.logoContainer}>
        <Link to="/">
          <img
            src={NeritoLogoGreen}
            alt=""

          />
        </Link>
      </div>
      <div className={classes.footerFilter}>
        <div className={classes.footerTop}>
          {/* <text>© MONECTA, I.F.P.E. S.A. de C.V. </text> */}
          <text className={classes.copyright}>Copyright © 2022 Nerito - Todos los derechos reservados.</text>
        </div>
        <div className={classes.termsContainer}>
          <Link to='/terminos' target='_top'>
            <Typography className={classes.link}>Términos y condiciones</Typography>
          </Link>
          <Link to='/politica-de-privacidad' target='_top'>
            <Typography className={classes.link}>Aviso de privacidad</Typography>
          </Link>
        </div>
        <div className={classes.singleRowLogo}>
          <div>
            <a
              href="https://www.facebook.com/NeritoApp/?modal=suggested_action&notif_id=1614122387213523&notif_t=page_user_activity&ref=notif"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebookLogo}
                alt=""
                className={classes.socialLogo}
              ></img>
            </a>
            <a
              href="https://www.instagram.com/neritooficial_latam/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagramLogo}
                alt=""
                className={classes.socialLogo}
              ></img>
            </a>
            <a
              href="https://twitter.com/neritoapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={twitterLogo}
                alt=""
                className={classes.socialLogo}
              ></img>
            </a>
          </div>
          <div className="right-logo">
            <a
              href="https://www.tiktok.com/@neritoapp?lang=es"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={tiktokLogo}
                alt=""
                className={classes.socialLogo}
              ></img>
            </a>
            <a
              href="https://www.linkedin.com/company/75551661/admin/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedinLogo}
                alt=""
                className={classes.socialLogo}
              ></img>
            </a>
            <a
              href="https://www.youtube.com/channel/UCr1YHcmay8HXGzQ6ymNwwTw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={youtubeLogo}
                alt=""
                className={classes.socialLogo}
              ></img>
            </a>
          </div>
        </div>
        {/* <div className={classes.footerBottom}>
          <ul className={click ? "go-to-legal active" : "go-to-legal"}>
            {FooterItems.map((item, index) => {
              return (
                <li key={index} className={classes.navLi}>
                  <Link to={item.url} className={item.cName} target="_top">
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
          </div> */}
      </div>
    </div>
  );
}

export default Footer;
