import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import CircularProgress from '@material-ui/core/CircularProgress';
import '../../assets/css/main.css';
import PrivacyNoticeData from './privacyNo‎tice.json';
import { GREEN } from '../../lib/constants';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';

const PrivacyNotice = (props) => {
  const classes = useStyles();
  //Pendiente de revisar
  // const [isLoading, setLoading] = useState(false);
  return (
    <div>
      <div className='privacy-container'>
        <div className='NavBar-area'></div>
        <div className='container'>
          {/* {isLoading ? (
            <div className='styleCircularProgress'>
              <CircularProgress color='secondary' />
            </div>
          ) : ( */}
            <>
              <div className={classes.container}>
                {PrivacyNoticeData.content.map((element) => {
                  switch (element.type) {
                    case 'title':
                      return (
                        <>
                          <text
                            className={`${classes.title} ${classes.defaultText}`}
                          >
                            {element.value}
                          </text>
                          {element.space ? <br /> : null}
                        </>
                      );
                    case 'date':
                      return (
                        <>
                          <text
                            className={`${classes.subTitle} ${classes.defaultText}`}
                          >
                            {element.value}
                          </text>
                          {element.space ? <br /> : null}
                        </>
                      );
                    case 'subTitle':
                      return (
                        <>
                          <br />
                          <text
                            className={`${classes.subTitle} ${classes.defaultText}`}
                          >
                            {element.value}
                          </text>
                          {element.space ? <br /> : null}
                          <br />
                        </>
                      );
                    case 'paragraph':
                      return (
                        <>
                          <text className={`${classes.defaultText}`}>
                            {element.value}
                          </text>
                          {element.space ? <br /> : null}
                        </>
                      );
                    case 'list':
                      return (
                        <>
                          <span className={`${classes.defaultText}`}>
                            {'• '}
                            {element.value}
                          </span>
                          {element.space ? <br /> : null}
                        </>
                      );
                    case 'link':
                      return (
                        <>
                          <a
                            href={element.value}
                            target='_blank'
                            className={`${classes.link} ${classes.defaultText}`}
                            rel='noopener noreferrer'
                            // onMouseEnter={()=> { setHover(true)}}
                            // onMouseLeave={()=> { setHover(false)}}
                          >
                            {element.value}
                          </a>
                          {element.space ? <br /> : null}
                        </>
                      );
                    case 'link-email':
                      return (
                        <>
                          <a
                            href={`mailto:${element.value}`}
                            target='_blank'
                            className={`${classes.link} ${classes.defaultText}`}
                            rel='noopener noreferrer'
                            // onMouseEnter={()=> { setHover(true)}}
                            // onMouseLeave={()=> { setHover(false)}}
                          >
                            {element.value}
                          </a>
                          {element.space ? <br /> : null}
                        </>
                      );
                    default:
                      return (
                        <text
                          className={`${classes.subTitle} ${classes.defaultText}`}
                        >
                          {element.value}
                        </text>
                      );
                  }
                })}
              </div>
            </>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default PrivacyNotice;

const rem = 16;

const useStyles = makeStyles({
  defaultText: {
    textAlign: 'left',
    fontFamily: 'Nunito',
    fontWeight: 500,
  },
  container: {
    backgroundColor: '#fff',
    width: 'calc(80%)',
    padding: '5% 10%',
    borderRadius: 20,
    marginBottom: '5%',
  },
  title: {
    fontSize: 2.25 * rem,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 1.25 * rem,
    fontWeight: 700,
  },
  link: {
    color: GREEN,
    ':visited': {
      color: 'pink',
    },
  },
});