import React from 'react';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import { Grid, Typography } from '@material-ui/core';
import NeritoBussines from '../../assets/images/neritoBussines.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faBoxesStacked, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PrimaryButton from '../../components/common/primaryButton';

const Business = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      
      <div className={classes.halfContainer}>
        <div className={classes.textContainer}>
          <FontAwesomeIcon icon={faStore} size='5x' color={theme.palette.yellow.main} />

          <div>
            <Typography className = {[classes.infoBold,classes.textRight]}>
              Con una cuenta en nerito, tu podras:
            </Typography>
            <Typography className = {classes.textRight}>
              Ahorrar en comisiones del banco​
            </Typography>
          </div>
          <ul>
            <li className = {classes.list}>
              Sustituir o complementar el uso de terminal 
              bancaria o cualquier punto de venta para 
              tarjetas bancarias
            ​</li>
            <li className = {classes.list}>
              Podras depositar y retirar efectivo, 
              pagar proveedores, recibir transferencias 
              desde cuentas bancarias, 
              o desde otras cuenta de nerito
            ​</li>
            <li className = {classes.list}>
              No necesitas un cajero o ir al banco,
              cualquier establecimeinto que use Nerito 
              podra servirte para depositar o retirar 
              efectivo
            ​</li>
            <li className = {classes.list}>
              Paga tu nomina a las cuentas nerito de tus empleado
            </li>
          </ul> 
        </div>
      </div>

      <div className={classes.halfContainer}>
        <Typography className={classes.blackTitle}>
            Haz crecer <br />
        </Typography>
        <Typography className={classes.greenTitle}>tu negocio</Typography>
        <Typography className={classes.thinTitle}>CUENTAS PARA NEGOCIOS</Typography>

        <img src={NeritoBussines} alt='' className={classes.neritoBussines} />
        <Link to='/comercios' target='_top'>
          <PrimaryButton marginVertical={'20px'} buttonText={classes.buttonText} minWidth={'300px'}>Crear tienda</PrimaryButton>
        </Link>
      </div>

    </div>
  )
}

export default Business;

const useStyles = makeStyles({
  textRight: {
    textAlign: 'right',
    width: '100%',
    fontSize: 20
  },
  greenTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.primary.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.black.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // justifyContent: 'center',
    gap: 30,
    width: '80%',
    height: '100%',
    padding: 50,
    textAlign: 'justify',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      gap: 20,
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 10,
      gap: 10,
    },
  },
  info: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '20px'
  },
  infoBold: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '20px',
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    // paddingHorizontal: '30px',
    marginTop: '100px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  neritoBussines: {
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  appInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '350px',
    marginBottom: '70px'
  },
  appIconContainer: {
    marginRight: '50px'
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '24px',
  },
  list: {
    lineHeight: 2,
    fontSize: 20,
    '&::marker': {
      color: theme.palette.yellow.main
    }
  }
});